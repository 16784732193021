import React from 'react';
import PropTypes from 'prop-types';
import { UX2, constants } from '@wsb/guac-widget-core';

const SlideshowHero = ({
  slide,
  index,
  forceH1,
  alignmentOption,
  heroIdPrefix,
  themeConfig,
  renderMode,
  currentSlide,
  showText,
  slideWidthPercent,
  fixedPosition,
  style = {}
}) => {
  const {
    maxWidthPercent = 100,
    heroTransition = 'fade',
    heroClass: Hero,
    heroProps = {}
  } = themeConfig;
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      paddingHorizontal: 'medium',
      ...(heroTransition === 'fade'
        ? {
          opacity: currentSlide === index && showText ? 1 : 0,
          transition: 'opacity 1s'
        }
        : {}),
      ...(fixedPosition && {
        // most SlideshowHero are overlaid on top of images, but not if using HeroCarousel
        position: 'fixed'
      }),
      ...style
    },
    innerContainer: {
      ['@md']: {
        maxWidth: `${slideWidthPercent || maxWidthPercent}%`
      }
    }
  };

  const { tagline, tagline2, cta } = slide;
  const hasCta = cta && cta.enabled;

  return tagline || tagline2 || hasCta ? (
    <UX2.Element.Block style={ styles.container } data-slide-hero={ `${heroIdPrefix}-${index}` }>
      <UX2.Element.Block style={ styles.innerContainer }>
        { renderMode !== constants.renderModes.PUBLISH && (
          <Hero
            forceH1={ forceH1 }
            slideIndex={ index }
            tagline={ tagline }
            tagline2={ tagline2 }
            cta={ hasCta && cta }
            alignmentOption={ alignmentOption }
            { ...heroProps }
            isSlideshow
          />
        ) }
      </UX2.Element.Block>
    </UX2.Element.Block>
  ) : null;
};

SlideshowHero.propTypes = {
  slide: PropTypes.object,
  index: PropTypes.number,
  forceH1: PropTypes.bool,
  alignmentOption: PropTypes.string,
  heroIdPrefix: PropTypes.string,
  themeConfig: PropTypes.object,
  renderMode: PropTypes.string,
  currentSlide: PropTypes.number,
  showText: PropTypes.bool,
  slideWidthPercent: PropTypes.number,
  style: PropTypes.object,
  fixedPosition: PropTypes.bool
};

SlideshowHero.defaultProps = {
  fixedPosition: true
};

export default SlideshowHero;
