export const populateHeroContent = (slideshow, index) => {
  const { heroIdPrefix } = slideshow;
  const group = document.querySelectorAll(`[data-slide-hero="${heroIdPrefix}-${index}"]`);
  if (group && group.length) {
    const heroElement = document.getElementById(`${heroIdPrefix}-content-${index}`);
    if (heroElement) {
      for (var i = 0; i < group.length; i++) {
        // react-img-carousel copies slides on the edges for fade transition, so this covers multiple instances
        const heroClone = i === 0 ? heroElement : heroElement.cloneNode(true);
        if (group[i].children.length <= 1) {
          group[i].appendChild(heroClone);
        }
      }
    }
  }
};

export const slidesHaveHeroContent = slideshow => {
  const { slides = [] } = slideshow;
  for (var i = 0; i < slides.length; i++) {
    const slideData = slides[i] || {};
    const { tagline, tagline2, cta } = slideData;
    if (tagline || tagline2 || (cta && cta.enabled)) {
      return true;
    }
  }
  return false;
};

export const slidesHaveMediaContent = slideshow => {
  const { slides = [] } = slideshow;
  for (var i = 0; i < slides.length; i++) {
    const slide = slides[i];
    if (slide && slide.image && slide.image.image) {
      return true;
    }
  }
  return false;
};
